<template>
    <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">Add Category</h1>
               
            </div>
            <div>
                <div class="card border-left-success  h-100 py-2">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Instructions</div>
                        <div class="h5 mb-0  text-gray-500" style="font-size:14px">Please contact us if you have any difficulty </div>
                    </div>
                </div>
                <div class="">
                    <div class="row">
                        <div class="col-md-12">
                            <form style="padding-top:30px">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputEmail4">Category Name <span>*</span></label>
                                    <input type="text" name="name" v-validate="'required'" v-model="product.name" class="form-control" id="inputEmail4" placeholder="">
                                    <span v-show="errors.has('name')" class="help is-danger"><i class="fa fa-warning"></i>{{ errors.first('name') }}</span>
                                </div>
                            </div>
                       
                           
                           
                            <div class="form-group">
                                <label for="description">Description </label>
                                <textarea type="text" v-model="product.description" name="description"  class="form-control" id="description" placeholder="please tell us about this category [optional]"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="description">Type </label>
                                <select name=""  id="" v-model="product.type" class="form-control">
                                    <option selected value="For All">For All</option>
                                    <option value="Creatives">Creatives</option>
                                </select>
                            </div>
                           
                            <div class="form-group">
                                <button class="btn btn-outline-primary" @click.prevent="saveProduct"> Save </button>
                            </div>
                               
                       
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    export default {
        data() {
            return {
                product: {
                    name: "",
                    description: "",
                    type: "For All"
                },
              
                show: false,
                
            }
        },
        methods: {
            saveProduct() {
                /* Get images into the images array as base64 */
                /* for (let i = 0; i < this.files.length; i++) {
                    var fileReader = new FileReader()
                    fileReader.readAsDataURL(this.files[i].data)
                    fileReader.onload = (e) => {
                        this.product.images[i] = e.target.result
                  
                    } 
                    
                } */
                this.$validator.validateAll().then(()=>{
                    if(!this.errors.any()) {
                        this.$store.dispatch('setLoading',true)
                        Crud.add('admin/categories',this.product)
                            .then((res) => {
                                console.log(res)
                                //this.$store.dispatch('success',"Category Added")
                                this.$store.dispatch('setLoading',false)
                                this.show = true
                                this.$toast.success(`${this.product.name} Added`);

                                //this.$store.dispatch('success',`${this.product.name} Added`)
                                this.$router.go(-1)
                                
                            })
                            .catch((err) => {
                                console.log(err)
                                this.$store.dispatch('reqError',err)
                                this.$store.dispatch('setLoading',false)
                            })
                    }
                })
                console.log(this.product)
                //console.log(this.files[0].data)
            }
        },
      
      
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
form span {
    color: red
}
.is-danger {
    color:red;
    }
</style>